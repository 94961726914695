import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { loadUsers } from '../../../features/table/tableActions';
import {
  JOB_STATUSES,
  JOB_STATUS_COMPLETED,
  JOB_TYPES,
  RATE_TYPES,
  RATE_TYPE_PER_HOUR,
  RATE_TYPE_PER_CODE,
  CLINIC_JOBS_PRACTICE_TYPES,
  PRIORITIES,
  RATE_TYPES_SELECT,
  JOB_DETAILS_VALUE_FIELDS,
  JOB_STATUS_MAPPING_COMPLETE,
  JOB_STATUS_FAILED,
  MAX_CODE_COUNT_LIMIT_ERROR,
  MAX_CODE_COUNT_LIMIT_ERROR_TITLE,
  MAX_CODE_COUNT_LIMIT_ERROR_MESSAGE,
} from '../../../constants/jobConstants';
import {
  getFullNameFromInfo,
  isAdmin,
  isMapper,
  isOnboardingUser,
} from '../../../helpers/userHelper';
import {
  clinicDetailsAllowedForUser,
  isClinicJob,
  isCustomJob,
  isEditable,
  isPracticeTypeAvailable,
  jobBelongsToUser,
} from '../../../helpers/jobsHelper';
import {
  downloadCompletedJob,
  updateJobDetails,
} from '../../../services/jobServices';
import { parseError } from '../../../helpers/errorHelper';
import { MAX_TEXT_LENGTH } from '../../../constants/constants';
import { jobDetailsEditSaved } from '../../../features/job/jobActions';
import MinutesSpent from '../../table/MinutesSpent';
import Select from 'react-select';
import { UserPresentation } from '../../table/UserPresentation.jsx';
import { getTooltipById, getValueById } from '../../../helpers/tableHelper.jsx';
import PriorityLabel from '../../common/PriorityLabel.jsx';
import NumberInputWithoutScroll from '../../common/NumberInputWithoutScroll';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import invoicedIcon from '../../../assets/icons/invoiced_icon.svg';
import downloadCSVIcon from '../../../assets/icons/download_csv.svg';
import infoIcon from '../../../assets/icons/info.svg';
import refreshErrorIcon from '../../../assets/icons/refresh_error_icon.svg';
import hiddenIcon from '../../../assets/icons/hidden.svg';
import { JobStatusCellIcon } from '../JobStatusCellIcon.jsx';
import { clientErrors } from '../../../constants/errorConstants.js';

export default function JobDetails({ mappingJobDetails, onClose }) {
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(null);
  const [hasEditableFields, setHasEditableFields] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const { users } = useSelector((state) => state.tableData);
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { dirtyFields, errors },
  } = useForm();
  useEffect(() => {
    if (!users && isAdmin(userInfo)) {
      dispatch(loadUsers());
    }
  }, [users]);
  const rateType = watch('rate_type');

  const isOnboardingUserRole = useMemo(() => isOnboardingUser(userInfo), []);

  const priorityOptions = PRIORITIES.map((priority) => ({
    value: priority.name,
    label: priority.name,
    bgColor: priority.bgColor,
    textColor: priority.textColor,
  }));

  const loadDetails = users !== null || !isAdmin(userInfo);

  useEffect(() => {
    setHasEditableFields(Object.keys(getValues()).length > 0);
  }, []);

  const submitForm = () => {
    setServerError(null);
    const formValues = watch();
    const changedFields = {};

    for (const key in dirtyFields) {
      //for priority and rate_type we need to extract value from object
      if (JOB_DETAILS_VALUE_FIELDS.includes(key)) {
        changedFields[key] = formValues[key]?.value;
      } else {
        changedFields[key] = formValues[key];
      }
    }

    updateJobDetails(mappingJobDetails.id, changedFields)
      .then(({ data }) => {
        dispatch(jobDetailsEditSaved(data));
      })
      .catch((error) => {
        setServerError(parseError(error).description);
      });
  };

  return (
    <div id="job-details" className="flex flex-col h-full flex-grow-1">
      {loadDetails && (
        <>
          <div className="flex max-w-2xl mb-4 text-lg font-medium">
            {`Job details (#${mappingJobDetails.id})`}
          </div>
          <form className="flex flex-col" onSubmit={handleSubmit(submitForm)}>
            <div className="job-details-row">
              <div className="job-details-row-title">Job Type:</div>
              <div>{JOB_TYPES[mappingJobDetails.job_type].name}</div>
            </div>
            {isCustomJob(mappingJobDetails) && (
              <div className="job-details-row">
                <div className="job-details-row-title">
                  Contains Excluded Codes:
                </div>
                <div>
                  {mappingJobDetails.include_excluded_codes ? 'Yes' : 'No'}
                </div>
              </div>
            )}
            <div className="job-details-row">
              <div className="job-details-row-title">Priority:</div>
              {isEditable('priority', userInfo, mappingJobDetails) ? (
                <>
                  {' '}
                  <Controller
                    name="priority"
                    control={control}
                    defaultValue={priorityOptions.find(
                      (p) => p.value === mappingJobDetails.priority
                    )}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="priority"
                        className="job-details-input-select"
                        options={priorityOptions}
                        aria-label="Priority"
                        defaultValue={priorityOptions.find(
                          (p) => p.value === mappingJobDetails.priority
                        )}
                        value={priorityOptions.find(
                          (p) => p.value === field.value
                        )}
                        getOptionLabel={(e) => (
                          <PriorityLabel
                            text={e.label}
                            bgColor={e.bgColor}
                            textColor={e.textColor}
                          />
                        )}
                        getValue={(e) => (
                          <PriorityLabel
                            text={e.label}
                            bgColor={e.bgColor}
                            textColor={e.textColor}
                          />
                        )}
                      />
                    )}
                  />
                </>
              ) : (
                <PriorityLabel
                  text={mappingJobDetails.priority}
                  bgColor={
                    PRIORITIES.find(
                      (style) => style.name === mappingJobDetails.priority
                    ).bgColor
                  }
                  textColor={
                    PRIORITIES.find(
                      (style) => style.name === mappingJobDetails.priority
                    ).textColor
                  }
                />
              )}
            </div>

            <div className="job-details-row">
              <div className="job-details-row-title">Available From:</div>
              {isEditable('available_at', userInfo, mappingJobDetails) ? (
                <div id="dateInputContainer">
                  <Controller
                    name="available_at"
                    id="available_at"
                    control={control}
                    defaultValue={new Date(mappingJobDetails.available_at)}
                    render={({ field }) => (
                      <DatePicker
                        className="job-details-date-input"
                        wrapperClassName="formDatepicker"
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => field.onChange(date)}
                        selected={new Date(field.value)}
                      />
                    )}
                  />
                </div>
              ) : (
                <div>
                  {moment(mappingJobDetails.available_at).format('YYYY-MM-DD')}
                </div>
              )}
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Project Tracking:</div>
              {isEditable('project_tracking', userInfo, mappingJobDetails) ? (
                <input
                  type="text"
                  data-testid="project-tracking-input"
                  className="job-details-text-input"
                  defaultValue={mappingJobDetails.project_tracking}
                  {...register('project_tracking')}
                />
              ) : (
                <div>{mappingJobDetails.project_tracking}</div>
              )}
            </div>
            {isClinicJob(mappingJobDetails) &&
              !clinicDetailsAllowedForUser(mappingJobDetails, userInfo) && (
                <div className="job-details-row">
                  <div className="job-details-row-title">Clinic Number:</div>
                  <div>{mappingJobDetails.practice_id}</div>
                </div>
              )}
            {clinicDetailsAllowedForUser(mappingJobDetails, userInfo) && (
              <>
                <div className="job-details-row">
                  <div className="job-details-row-title">
                    Clinic Number-Name:
                  </div>
                  <div>
                    {mappingJobDetails.practice_id}-
                    {mappingJobDetails.practice_name}
                  </div>
                </div>
                <div className="job-details-row">
                  <div className="job-details-row-title">City, State:</div>
                  <div>
                    {mappingJobDetails.practice_city},{' '}
                    {mappingJobDetails.practice_state}
                  </div>
                </div>
                <div className="job-details-row">
                  <div className="job-details-row-title">Website:</div>
                  <a
                    href={
                      mappingJobDetails.website
                        ? mappingJobDetails.website.startsWith('http://') ||
                          mappingJobDetails.website.startsWith('https://')
                          ? mappingJobDetails.website
                          : `https://${mappingJobDetails.website}`
                        : '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-purple-vs hover:underline hover:text-purple-hover"
                  >
                    {mappingJobDetails.website}
                  </a>
                </div>
              </>
            )}
            {isClinicJob(mappingJobDetails) && (
              <>
                <div className="job-details-row">
                  <div className="job-details-row-title">
                    Salesforce Case Number:
                  </div>
                  {isEditable(
                    'salesforce_case_number',
                    userInfo,
                    mappingJobDetails
                  ) ? (
                    <NumberInputWithoutScroll
                      id="salesforce_case_number"
                      className="job-details-text-input"
                      registerProps={register(
                        'salesforce_data.salesforce_case_number'
                      )}
                      defaultValue={
                        mappingJobDetails.salesforce_data
                          ?.salesforce_case_number
                      }
                    />
                  ) : (
                    <div>
                      {
                        mappingJobDetails.salesforce_data
                          ?.salesforce_case_number
                      }
                    </div>
                  )}
                </div>
                <div className="job-details-row">
                  <div className="job-details-row-title">
                    Onboarding Category:
                  </div>
                  {isEditable(
                    'onboarding_category',
                    userInfo,
                    mappingJobDetails
                  ) ? (
                    <NumberInputWithoutScroll
                      id="onboarding_category"
                      className="job-details-text-input"
                      registerProps={register(
                        'salesforce_data.onboarding_category'
                      )}
                      defaultValue={
                        mappingJobDetails.salesforce_data?.onboarding_category
                      }
                    />
                  ) : (
                    <div>
                      {mappingJobDetails.salesforce_data?.onboarding_category}
                    </div>
                  )}
                </div>
              </>
            )}
            <>
              {' '}
              <div className="job-details-row-notes-title">
                <div className="job-details-row-title">Job Description</div>
              </div>
              <div className="job-details-row-notes">
                {isEditable(
                  'admin_description',
                  userInfo,
                  mappingJobDetails
                ) ? (
                  <>
                    {' '}
                    <textarea
                      id="admin_description"
                      className="job-details-textarea"
                      defaultValue={mappingJobDetails.admin_description}
                      {...register('admin_description', {
                        maxLength: MAX_TEXT_LENGTH,
                      })}
                    />
                    {errors.admin_description?.type === 'maxLength' && (
                      <p className="text-red-500 text-xs">
                        Job Description can have up to {MAX_TEXT_LENGTH}{' '}
                        characters
                      </p>
                    )}
                  </>
                ) : (
                  <div>{mappingJobDetails.admin_description}</div>
                )}
              </div>
            </>
            {isAdmin(userInfo) && (
              <>
                {' '}
                <div className="job-details-row-notes-title">
                  <div className="job-details-row-title">Admin Notes</div>
                </div>
                <div className="job-details-row-notes">
                  <>
                    <textarea
                      id="admin_notes"
                      className="job-details-textarea"
                      defaultValue={mappingJobDetails.admin_notes}
                      {...register('admin_notes', {
                        maxLength: MAX_TEXT_LENGTH,
                      })}
                    />
                    {errors.admin_notes?.type === 'maxLength' && (
                      <p className="text-red-500 text-xs">
                        Admin Notes can have up to {MAX_TEXT_LENGTH} characters
                      </p>
                    )}
                  </>
                </div>
              </>
            )}
            {(jobBelongsToUser(mappingJobDetails, userInfo.id) ||
              isAdmin(userInfo)) && (
              <>
                {' '}
                <div className="job-details-row-notes-title">
                  <div className="job-details-row-title">Mapper Notes</div>
                </div>
                <div className="job-details-row-notes">
                  <>
                    <textarea
                      id="notes"
                      className="job-details-textarea"
                      defaultValue={mappingJobDetails.notes}
                      {...register('notes', {
                        maxLength: MAX_TEXT_LENGTH,
                      })}
                    />
                    {errors.notes?.type === 'maxLength' && (
                      <p className="text-red-500 text-xs">
                        Mapper Notes can have up to {MAX_TEXT_LENGTH} characters
                      </p>
                    )}
                  </>
                </div>
              </>
            )}
            {isPracticeTypeAvailable(userInfo, mappingJobDetails) && (
              <>
                <div className="job-details-row-flex-height">
                  <div className="job-details-row-title">Practice Type</div>
                  {isEditable('practice_type', userInfo, mappingJobDetails) ? (
                    <div className="w-full" data-testid="edit-practice-type">
                      <Controller
                        name="practice_type"
                        control={control}
                        defaultValue={mappingJobDetails.practice_type || []}
                        rules={{
                          required:
                            mappingJobDetails.status ===
                            JOB_STATUS_MAPPING_COMPLETE
                              ? 'Practice Type is required'
                              : false,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isMulti
                            options={CLINIC_JOBS_PRACTICE_TYPES}
                            className="job-details-input-select"
                            value={CLINIC_JOBS_PRACTICE_TYPES.filter((option) =>
                              field.value.includes(option.value)
                            )}
                            onChange={(selectedOptions) =>
                              field.onChange(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className="overflow-hidden">
                      {mappingJobDetails.practice_type?.join(', ')}
                    </div>
                  )}
                </div>
                {errors.practice_type && (
                  <p className="text-red-500 text-center text-xs">
                    {errors.practice_type.message}
                  </p>
                )}
              </>
            )}
            <div className="job-details-header">Protocol</div>
            <div className="job-details-row-flex-height mb-0 flex justify-between items-start">
              <div className="flex flex-col w-[30%]">
                <div className="job-details-row-title mb-1">DHPP:</div>
                {isEditable('vaccine_protocol', userInfo, mappingJobDetails) ? (
                  <>
                    <input
                      id="protocolDhpp"
                      data-testid="dhpp-input"
                      type="text"
                      className="job-details-text-input"
                      {...register('vaccine_protocol.dhpp', {
                        required: isOnboardingUserRole
                          ? 'Protocol is required'
                          : false,
                      })}
                      defaultValue={
                        mappingJobDetails.vaccine_protocol?.dhpp || ''
                      }
                    />
                    <div className="h-4">
                      {errors.vaccine_protocol?.dhpp && (
                        <p className="text-red-500 text-xs">
                          {errors.vaccine_protocol.dhpp.message}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <div>{mappingJobDetails.vaccine_protocol?.dhpp}</div>
                )}
              </div>

              <div className="flex flex-col w-[30%]">
                <div className="job-details-row-title mb-1">FVRCP:</div>
                {isEditable('vaccine_protocol', userInfo, mappingJobDetails) ? (
                  <>
                    <input
                      id="protocolFvrcp"
                      data-testid="fvrcp-input"
                      type="text"
                      className="job-details-text-input"
                      {...register('vaccine_protocol.fvrcp', {
                        required: isOnboardingUserRole
                          ? 'Protocol is required'
                          : false,
                      })}
                      defaultValue={
                        mappingJobDetails.vaccine_protocol?.fvrcp || ''
                      }
                    />
                    <div className="h-4">
                      {errors.vaccine_protocol?.fvrcp && (
                        <p className="text-red-500 text-xs">
                          {errors.vaccine_protocol.fvrcp.message}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <div>{mappingJobDetails.vaccine_protocol?.fvrcp}</div>
                )}
              </div>

              <div className="flex flex-col w-[30%]">
                <div className="job-details-row-title mb-1">Rabies:</div>
                {isEditable('vaccine_protocol', userInfo, mappingJobDetails) ? (
                  <>
                    {' '}
                    <input
                      id="protocolRabies"
                      data-testid="rabies-input"
                      type="text"
                      className="job-details-text-input"
                      {...register('vaccine_protocol.rabies', {
                        required: isOnboardingUserRole
                          ? 'Protocol is required'
                          : false,
                      })}
                      defaultValue={
                        mappingJobDetails.vaccine_protocol?.rabies || ''
                      }
                    />
                    <div className="h-4">
                      {errors.vaccine_protocol?.rabies && (
                        <p className="text-red-500 text-xs">
                          {errors.vaccine_protocol.rabies.message}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <div>{mappingJobDetails.vaccine_protocol?.rabies}</div>
                )}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Total Code Count:</div>
              <div>{mappingJobDetails.unverified_code_count}</div>
            </div>
            {mappingJobDetails.rate_type === RATE_TYPE_PER_CODE &&
              mappingJobDetails.status === JOB_STATUS_COMPLETED && (
                <div className="job-details-row">
                  <div className="job-details-row-title">
                    Mapped Code Count:
                  </div>
                  <div>{mappingJobDetails.mapped_code_count}</div>
                </div>
              )}
            <div className="job-details-row">
              <div className="job-details-row-title">Rate:</div>
              {isEditable('rate', userInfo, mappingJobDetails) ? (
                <div data-testid="edit-rate" className="flex flex-1">
                  <NumberInputWithoutScroll
                    id="rate"
                    registerProps={register('rate', {
                      required: true,
                    })}
                    step="0.01"
                    defaultValue={mappingJobDetails.rate}
                    className="job-details-text-input"
                  />
                  <label htmlFor="rate-type" className="sr-only">
                    Rate
                  </label>
                </div>
              ) : (
                <div>{`$${mappingJobDetails.rate}`}</div>
              )}
            </div>
            {errors.rate?.type === 'required' && (
              <p className="text-red-500 text-center ml-4 -mt-2 mb-2 text-xs">
                Rate is required
              </p>
            )}

            <div className="job-details-row">
              <div className="job-details-row-title">Rate Type:</div>
              {isEditable('rate_type', userInfo, mappingJobDetails) ? (
                <div className="flex-1" data-testid="edit-rate-type">
                  <Controller
                    name="rate_type"
                    id="rate-type"
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    defaultValue={RATE_TYPES_SELECT.find((p) => {
                      return p.value === mappingJobDetails.rate_type;
                    })}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="rate-type"
                        options={RATE_TYPES_SELECT}
                        className="job-details-input-select"
                        aria-label="Rate Type"
                      />
                    )}
                  />
                </div>
              ) : (
                <div>{RATE_TYPES[mappingJobDetails.rate_type]}</div>
              )}
            </div>
            {rateType?.value === RATE_TYPE_PER_HOUR && (
              <div className="job-details-row time-spent">
                <div className="job-details-row-title">Time Spent:</div>
                <Controller
                  name="mapped_time"
                  control={control}
                  defaultValue={mappingJobDetails.mapped_time || 0}
                  render={() => (
                    <MinutesSpent
                      jobDetails={mappingJobDetails}
                      editEnabled={isEditable(
                        'mapped_time',
                        userInfo,
                        mappingJobDetails
                      )}
                      applyInlineEdit={(newTimeSpent) =>
                        setValue('mapped_time', newTimeSpent, {
                          shouldDirty: true,
                        })
                      }
                    />
                  )}
                />
              </div>
            )}
            <div className="job-details-row">
              <div className="job-details-row-title">Claimed by:</div>
              <div>
                {/*handle users not being available for mappers*/}
                {isMapper(userInfo) &&
                  jobBelongsToUser(mappingJobDetails, userInfo.id) &&
                  getFullNameFromInfo(userInfo)}
                {isAdmin(userInfo) && mappingJobDetails.claimed_by && (
                  <UserPresentation
                    label={getValueById(users, mappingJobDetails.claimed_by)}
                    offset={[30, 5]}
                    tooltip={getTooltipById(
                      users,
                      mappingJobDetails.claimed_by
                    )}
                  />
                )}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Date Claimed:</div>
              <div>
                {mappingJobDetails.claimed_at
                  ? moment(mappingJobDetails.claimed_at).format('YYYY-MM-DD')
                  : null}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Mapping Completed:</div>
              <div>{mappingJobDetails.mapping_completed_at}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Onboarding Completed:</div>
              <div>{mappingJobDetails.completed_at}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Status:</div>
              <div>{JOB_STATUSES[mappingJobDetails.status]}</div>
              {mappingJobDetails.status === JOB_STATUS_FAILED &&
                mappingJobDetails.error_message && (
                  <JobStatusCellIcon
                    testId="failedIcon"
                    offset={[110, 10]}
                    iconSrc={infoIcon}
                    tooltipText={mappingJobDetails.error_message}
                  />
                )}
              {mappingJobDetails.status !== JOB_STATUS_FAILED &&
                mappingJobDetails.error_message ===
                  MAX_CODE_COUNT_LIMIT_ERROR && (
                  <JobStatusCellIcon
                    testId="refreshFailedIcon"
                    offset={[290, 10]}
                    iconSrc={refreshErrorIcon}
                    tooltipTitle={MAX_CODE_COUNT_LIMIT_ERROR_TITLE}
                    tooltipText={MAX_CODE_COUNT_LIMIT_ERROR_MESSAGE}
                  />
                )}
              {mappingJobDetails.hidden_from_mapper && (
                <JobStatusCellIcon
                  testId="hiddenIcon"
                  offset={[52, 10]}
                  iconSrc={hiddenIcon}
                  tooltipText="Hidden from Mapper"
                />
              )}
              {mappingJobDetails.invoiced && (
                <JobStatusCellIcon
                  testId="invoicedIcon"
                  offset={[23, 10]}
                  iconSrc={invoicedIcon}
                  tooltipText="Invoiced"
                />
              )}
              {mappingJobDetails.status === JOB_STATUS_COMPLETED && (
                <JobStatusCellIcon
                  testId="downloadCSVIcon"
                  additionalClasses="cursor-pointer"
                  offset={[39, 10]}
                  iconSrc={downloadCSVIcon}
                  tooltipText="Download CSV"
                  jobId={mappingJobDetails.id}
                  actionHandler={() =>
                    downloadCompletedJob(mappingJobDetails.id)
                      .then((res) => {
                        if (res.data.download_url) {
                          const link = document.createElement('a');
                          link.href = res.data.download_url;
                          document.body.appendChild(link);
                          link.click();
                        } else
                          setServerError(
                            clientErrors.completedJobCsv.description
                          );
                      })
                      .catch((error) =>
                        setServerError(parseError(error).description)
                      )
                  }
                />
              )}
            </div>
            <div className="job-details-row h-4 w-full mb-4 text-center text-red-500">
              {serverError}
            </div>
            {hasEditableFields ? (
              <div
                data-testid="job-details-edit-buttons"
                className="text-center"
              >
                <OutlineButton
                  outlineColor="border-gray"
                  textColor="text-gray"
                  text="Cancel"
                  widthClass="w-[120px]"
                  heightClass="h-10"
                  onClick={onClose}
                />
                <ColoredButton
                  fillColor="bg-vs-green"
                  text="Save"
                  widthClass="w-[120px]"
                  heightClass="h-10"
                  submit={true}
                  disabled={
                    Object.keys(dirtyFields).length === 0 ||
                    Object.keys(errors).length > 0
                  }
                />
              </div>
            ) : (
              <div
                data-testid="job-details-close-button"
                className="text-center"
              >
                <OutlineButton
                  outlineColor="border-gray"
                  textColor="text-gray"
                  text="Close"
                  widthClass="w-[120px]"
                  heightClass="h-10"
                  onClick={onClose}
                />
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
}
