import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  canDeleteJob,
  canMarkJobAsInvoiced,
  canMarkJobMappingComplete,
  canUnhideJobFromMapper,
} from '../../helpers/jobsHelper';
import { useDispatch } from 'react-redux';
import { openViewJobDetails } from '../../features/modal/modalActions';
import more from '../../assets/icons/more.svg';
import {
  COMPLETE_MAPPING_WARNING_JOB_ACTION,
  DELETE_JOB_ACTION,
  COMPLETE_MAPPING_ACTION,
  COMPLETE_MAPPING_ACTION_LABEL,
  DELETE_JOB_ACTION_LABEL,
  INVOICE_JOB_ACTION,
  INVOICE_JOB_ACTION_LABEL,
  UNHIDE_JOB_ACTION_LABEL,
  UNHIDE_JOB_ACTION,
  CLINIC_JOB_TYPE,
  COMPLETE_MAPPING_PRACTICE_TYPE_WARNING_ACTION,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION,
} from '../../constants/jobConstants';
import { usePopper } from 'react-popper';
import { getUnverifiedCodes } from '../../services/mappingDataServices';

export const JobActionsDropdown = ({
  setJobAction,
  setErrorAlert,
  mappingJobDetails,
  userInfo,
}) => {
  const dispatch = useDispatch();

  const getDropdownItems = () => {
    const dropdownItems = [];
    canUnhideJobFromMapper(mappingJobDetails, userInfo) &&
      dropdownItems.push({
        label: UNHIDE_JOB_ACTION_LABEL,
        action: () =>
          setJobAction({
            action: UNHIDE_JOB_ACTION,
            jobId: mappingJobDetails.id,
          }),
      });
    dropdownItems.push({
      label: 'Details',
      action: async () => {
        try {
          await dispatch(openViewJobDetails(mappingJobDetails.id)).unwrap();
        } catch (error) {
          setErrorAlert({ parsedError: error });
        }
      },
    });
    canMarkJobMappingComplete(mappingJobDetails, userInfo) &&
      dropdownItems.push({
        label: COMPLETE_MAPPING_ACTION_LABEL,
        action: () => {
          if (
            mappingJobDetails.job_type === CLINIC_JOB_TYPE &&
            mappingJobDetails.practice_type.length === 0
          ) {
            setJobAction({
              action:
                mappingJobDetails.unverified_code_count <= 100
                  ? COMPLETE_MAPPING_PRACTICE_TYPE_WARNING_ACTION
                  : COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION,
              jobId: mappingJobDetails.id,
              jobInfo: mappingJobDetails,
            });
          } else {
            getUnverifiedCodes(mappingJobDetails.id)
              .then((unverifiedCodesExists) => {
                if (unverifiedCodesExists) {
                  setJobAction({
                    action: COMPLETE_MAPPING_WARNING_JOB_ACTION,
                    jobId: mappingJobDetails.id,
                    jobInfo: mappingJobDetails,
                  });
                } else {
                  setJobAction({
                    action: COMPLETE_MAPPING_ACTION,
                    jobId: mappingJobDetails.id,
                    jobInfo: mappingJobDetails,
                  });
                }
              })
              .catch((error) => {
                setErrorAlert({ error: error });
              });
          }
        },
      });
    canMarkJobAsInvoiced(mappingJobDetails, userInfo) &&
      dropdownItems.push({
        label: INVOICE_JOB_ACTION_LABEL,
        action: () =>
          setJobAction({
            action: INVOICE_JOB_ACTION,
            jobIds: [mappingJobDetails.id],
          }),
      });
    canDeleteJob(mappingJobDetails, userInfo) &&
      dropdownItems.push({
        label: DELETE_JOB_ACTION_LABEL,
        action: () =>
          setJobAction({
            action: DELETE_JOB_ACTION,
            jobId: mappingJobDetails.id,
          }),
        critical: true,
      });
    return dropdownItems;
  };

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  let items = getDropdownItems();
  if (items.length > 0) {
    return (
      <Menu as="div" className="w-[110px]">
        <Menu.Button className="float-right p-[6px] " ref={setReferenceElement}>
          <img src={more} alt="More Actions" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="absolute w-[160px] right-0 z-10 mt-2 rounded bg-white border-[1px] divide-y divide-gray-100"
          >
            {getDropdownItems().map((item) => (
              <div
                key={item.label}
                className={`py-1 ${
                  item.disabled ? 'pointer-events-none' : 'cursor-pointer'
                }`}
              >
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={`block px-2 py-2 text-12 ${
                        active && 'bg-neutral-100 text-gray-900 rounded'
                      } ${item.disabled ? 'text-gray-350' : 'text'}
                    ${
                      item.critical
                        ? 'hover:bg-red-200 hover:text-red text-red'
                        : 'text'
                    }                `}
                      onClick={item.action}
                    >
                      {item.label}
                    </span>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
};
