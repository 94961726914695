import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getJobDetails } from '../../services/jobServices';
import { parseError } from '../../helpers/errorHelper';

export const loadJobDetails = createAsyncThunk(
  'job/getJobDetails',
  async (jobId, { rejectWithValue }) => {
    try {
      const { data } = await getJobDetails(jobId);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const jobDetailsEditSaved = createAction('jobDetailsEditSaved');
