import { createSlice } from '@reduxjs/toolkit';
import { jobDetailsEditSaved, loadJobDetails } from './jobActions';
import { logout, resetState } from '../user/userActions';

const initialState = {
  mappingJobDetails: null,
  loadingJobDetails: false,
  loadingJobDetailsError: null,
};
export const jobSlice = createSlice({
  name: 'job',
  initialState: initialState,
  extraReducers: {
    [loadJobDetails.pending]: (state) => {
      state.loadingJobDetails = true;
      state.loadingJobDetailsError = null;
    },
    [loadJobDetails.fulfilled]: (state, { payload }) => {
      state.mappingJobDetails = payload;
      state.loadingJobDetails = false;
      state.loadingJobDetailsError = null;
    },
    [loadJobDetails.rejected]: (state, { payload }) => {
      state.loadingJobDetailsError = payload;
      state.loadingJobDetails = false;
    },
    [jobDetailsEditSaved]: (state, { payload }) => {
      state.mappingJobDetails = payload;
      state.loadingJobDetails = false;
      state.loadingJobDetailsError = null;
    },
    [logout]: (state) => {
      state.loadingJobDetails = null;
      state.mappingJobDetails = null;
      state.loadingJobDetailsError = null;
    },
    [resetState]: () => initialState,
  },
});

export default jobSlice.reducer;
