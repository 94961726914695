import { memo, useCallback, useEffect, useRef, useState } from 'react';
import addHours from '../../assets/icons/add_hours.svg';
import addHoursNoBorder from '../../assets/icons/add_hours_no_border.svg';
import subtractHours from '../../assets/icons/subtract_hours.svg';
import { useDispatch } from 'react-redux';
import { updateJobDetails } from '../../services/jobServices';
import { jobDetailsEditSaved } from '../../features/job/jobActions';
import { formatHours } from '../../helpers/jobsTableHelper';
import { parseError } from '../../helpers/errorHelper';

const MinutesSpent = memo(function MinutesSpent({
  jobDetails,
  setErrorAlert = null,
  setServerError = null,
  editEnabled = true,
  totalTimeClassName = null,
  applyInlineEdit = null,
}) {
  const dispatch = useDispatch();
  const [minutes, setMinutes] = useState(0);
  const [activePicker, setActivePicker] = useState(null);
  const [confirmationEnabled, setConfirmationAbility] = useState(false);
  const [hoursPicked, setHoursPicked] = useState(null);
  const [minutesPicked, setMinutesPicked] = useState(null);
  const newHours = useRef();
  const newMinutes = useRef();

  useEffect(() => {
    setMinutes(jobDetails.mapped_time || 0);
  }, [jobDetails.mapped_time]);

  useEffect(() => {
    if (
      (hoursPicked && parseInt(hoursPicked) > 0) ||
      (minutesPicked && parseInt(minutesPicked) > 0)
    ) {
      setConfirmationAbility(true);
    } else setConfirmationAbility(false);
  }, [hoursPicked, minutesPicked]);

  const resetState = () => {
    setHoursPicked(null);
    setMinutesPicked(null);
    setConfirmationAbility(false);
  };

  const handleAddingHours = () => {
    let newMinutesAdded = convertToMinutes();
    let newMappedTime = 0;
    if (activePicker === 'add') {
      newMappedTime = minutes + newMinutesAdded;
    } else {
      newMappedTime =
        minutes - newMinutesAdded > 0 ? minutes - newMinutesAdded : 0;
    }
    setActivePicker(null);

    if (applyInlineEdit) {
      applyInlineEdit(newMappedTime);
      setMinutes(newMappedTime);
      resetState();
    } else {
      updateJobDetails(jobDetails.id, { mapped_time: newMappedTime })
        .then(({ data }) => {
          dispatch(jobDetailsEditSaved(data));
          setMinutes(data.mapped_time);
          resetState();
        })
        .catch((error) => {
          if (setErrorAlert) {
            setErrorAlert({ error: error });
          }
          if (setServerError) {
            setServerError(parseError(error).description);
          }
          resetState();
        });
    }
  };

  const handlePickerChange = (type) => {
    setActivePicker(type);
  };

  const cancelAddingHours = () => {
    setActivePicker(null);
    setHoursPicked(null);
    setMinutesPicked(null);
    setConfirmationAbility(false);
  };

  const renderPicker = () => {
    return (
      <div className="inline-flex">
        {activePicker === 'add' && (
          <img src={addHoursNoBorder} title="Add time" className="ml-1 mr-2" />
        )}
        {activePicker === 'subtract' && (
          <img src={subtractHours} title="Subtract time" className="mr-1" />
        )}
        <div className="border border-darker-gray rounded-sm inline-flex px-1">
          <input
            type="number"
            ref={newHours}
            className="hours-picker"
            maxLength="2"
            value={hoursPicked || '00'}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                e.preventDefault();
                setHoursPicked(null);
              }
              if (e.key === 'ArrowDown') {
                e.preventDefault();
                let previousValue = hoursPicked || 0;
                if (parseInt(previousValue) > 0) {
                  let newHoursToSet =
                    parseInt(previousValue) - 1 < 10
                      ? `0${parseInt(previousValue) - 1}`
                      : parseInt(previousValue) - 1;
                  setHoursPicked(newHoursToSet);
                }
              }
              if (e.key === 'ArrowUp') {
                e.preventDefault();
                let previousValue = hoursPicked || 0;
                if (parseInt(previousValue) < 99) {
                  let newHoursToSet =
                    parseInt(previousValue) + 1 < 10
                      ? `0${parseInt(previousValue) + 1}`
                      : parseInt(previousValue) + 1;
                  setHoursPicked(newHoursToSet);
                }
              }
              if (e.key === 'ArrowRight') {
                newMinutes?.current?.focus();
              }
            }}
            onChange={(e) => {
              let previousValue = parseInt(hoursPicked);
              let currentInputValue = parseInt(e.nativeEvent.data);
              if (!previousValue) {
                setHoursPicked(`0${currentInputValue}`);
              } else if (previousValue < 10) {
                setHoursPicked(`${previousValue}${currentInputValue}`);
                newMinutes?.current?.focus();
              } else if (previousValue >= 10) {
                setHoursPicked(`0${currentInputValue}`);
              }
            }}
          />
          <div className="mt-0.5">:</div>
          <input
            type="number"
            ref={newMinutes}
            className="hours-picker"
            maxLength="2"
            value={minutesPicked || '00'}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                e.preventDefault();
                setMinutesPicked(null);
              }
              if (e.key === 'ArrowDown') {
                e.preventDefault();
                let previousValue = minutesPicked || 0;
                if (parseInt(previousValue) > 0) {
                  setMinutesPicked(
                    parseInt(previousValue) - 1 < 10
                      ? `0${parseInt(previousValue) - 1}`
                      : parseInt(previousValue) - 1
                  );
                }
              }
              if (e.key === 'ArrowUp') {
                e.preventDefault();
                let previousValue = minutesPicked || 0;
                if (parseInt(previousValue) < 59)
                  setMinutesPicked(
                    parseInt(previousValue) + 1 < 10
                      ? `0${parseInt(previousValue) + 1}`
                      : parseInt(previousValue) + 1
                  );
              }
              if (e.key === 'ArrowLeft') {
                newHours?.current?.focus();
              }
            }}
            onChange={(e) => {
              let previousValue = parseInt(minutesPicked);
              let currentInputValue = parseInt(e.nativeEvent.data);
              if (!previousValue) {
                setMinutesPicked(`0${currentInputValue}`);
              } else if (previousValue < 10) {
                setMinutesPicked(
                  `${previousValue < 6 ? previousValue : 5}${currentInputValue}`
                );
              } else if (previousValue >= 10) {
                setMinutesPicked(`0${currentInputValue}`);
              }
            }}
          />
        </div>
        <div
          onClick={cancelAddingHours}
          className="ml-2 cursor-pointer cancel-button-small"
        />
        {confirmationEnabled ? (
          <div
            onClick={handleAddingHours}
            className="ml-2 cursor-pointer approve-button-small"
          />
        ) : (
          <div className="ml-2 approve-button-small-disabled" />
        )}
      </div>
    );
  };

  const convertToMinutes = useCallback(() => {
    return (
      parseInt(`${newHours?.current?.value}`) * 60 +
      parseInt(`${newMinutes?.current?.value}`)
    );
  }, [newHours, newMinutes]);

  return (
    <>
      <div
        data-testid="formated-time-spent"
        className={`mr-2 ${totalTimeClassName}`}
      >
        {formatHours(minutes)}
      </div>
      {editEnabled ? (
        <>
          {!activePicker && (
            <img
              data-testid="subtract-hours-icon"
              src={subtractHours}
              onClick={() => handlePickerChange('subtract')}
              className="cursor-pointer"
              title="Subtract time"
            />
          )}
          {!activePicker && (
            <img
              data-testid="add-hours-icon"
              src={addHours}
              onClick={() => handlePickerChange('add')}
              className="cursor-pointer"
              title="Add time"
            />
          )}
          {activePicker && renderPicker()}
        </>
      ) : null}
    </>
  );
});

export default MinutesSpent;
