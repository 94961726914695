import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useGridCellEditor } from 'ag-grid-react';
import Select from 'react-select';
import { htmlDecode, parseNameFromStringWithId } from '../helpers/tableHelper';
import { enterPressed } from '../utils/helper';
import { selectFilter } from '../helpers/selectHelper';
import { revenue_category_id } from '../constants/constants';

const customClassName = 'ms-single-select';
const focusedElementSelector = `.${customClassName}__option--is-focused`;
const selectedElementSelector = `.${customClassName}__option--is-selected`;

/**
 *
 * props:
 * options: List of values that will be used to populate react-select options, placeholder and initial value
 * isSelectable<optional>: Predicate that will be used (if present) to filter out only values that can be selected in dropdown (i.e. mappable RC/CT) - this options will be used in react-select and in formatValue fn
 */
const isRevenueCategoryColumn = (column) =>
  column === revenue_category_id.field;

export default memo(
  ({
    value,
    onValueChange,
    stopEditing,
    column,
    eventKey,
    options,
    isSelectable,
    isSearchable = false,
  }) => {
    const selectableOptions = isSelectable
      ? options.filter((option) => isSelectable(option))
      : options;
    let parsedValue =
      value && isRevenueCategoryColumn(column.colId)
        ? parseNameFromStringWithId(value)
        : value;
    const localValue = value
      ? options.find(
          (option) => option.value === parsedValue || option.id === parsedValue
        )
      : null;
    //handle first letter lost on typing and enter key - AG GRID issue (not resolved) - only for RC (serchable)
    const [inputValue, setInputValue] = useState(
      isSearchable && eventKey && eventKey.length === 1 ? eventKey : ''
    );
    const [outsideScroll, setOutsideScroll] = useState(false);
    const [clickOnMenu, setClickedOnMenu] = useState(false);
    const [clickOnSelf, setClickedSelf] = useState(false);
    const [enterSelection, setEnterSelection] = useState(false);
    const containerRef = useRef(null);

    // we have to listen on the scrolling events outside of the react-select
    // and react to it - when we start scrolling outside of menu list, we have to finish editing
    useEffect(() => {
      if (outsideScroll) {
        stopEditing();
      }
    }, [outsideScroll]);

    useEffect(() => {
      document.addEventListener('scroll', handleScrollOutside, true);
      return () => {
        document.removeEventListener('scroll', handleScrollOutside, true);
      };
    }, []);

    const setValue = useCallback(() => {
      // handling finish editing on enter
      // accepting current highlighted option if present, if only selected is present use that one
      let focusedOption = document.querySelector(focusedElementSelector);
      let selectedOption = document.querySelector(selectedElementSelector);

      if (focusedOption) {
        onValueChange(formatValue(htmlDecode(focusedOption.innerHTML)));
      } else if (selectedOption) {
        onValueChange(formatValue(htmlDecode(selectedOption.innerHTML)));
      }
      stopEditing();
    }, []);

    const handleScrollOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOutsideScroll(true);
      }
    };

    const CustomStyle = {
      control: (styles) => ({
        ...styles,
        width: column.actualWidth,
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        display: 'none',
      }),
    };

    const onInputChange = useCallback((e) => {
      setInputValue(e);
      scrollOptionIntoView(focusedElementSelector);
    }, []);

    const isCancelAfterEnd = useCallback(() => {
      // if options list is empty - cancel edit
      // if scroll happened before confirming(pressing enter)- cancel editing.
      // if option is not confirmed with enter or clicking on option in menu - cancel editing (i.e. - click outside of the menu)
      // Either of those is sufficient for selection to be accepted.
      return (
        (inputValue && optionsEmpty()) ||
        outsideScroll ||
        clickOnSelf ||
        !(clickOnMenu || enterSelection)
      );
    }, [inputValue, outsideScroll, clickOnSelf, clickOnMenu, enterSelection]);

    useGridCellEditor({ isCancelAfterEnd });

    const formatValue = (value) => {
      return selectableOptions.find((v) => v.value === value).id;
    };

    const onMenuOpen = () => {
      scrollOptionIntoView(selectedElementSelector);
    };

    const optionsEmpty = () => {
      return !selectableOptions.some((candidate) =>
        selectFilter(candidate, inputValue)
      );
    };

    const scrollOptionIntoView = (optionClass) => {
      setTimeout(() => {
        const option = document.querySelector(optionClass);
        option &&
          option.scrollIntoView({ behavior: 'instant', block: 'start' });
      }, 15);
    };

    return (
      <div
        translate="no"
        ref={containerRef}
        onClickCapture={(e) => {
          if (e.target.className.includes(`${customClassName}__single-value`)) {
            setClickedSelf(true);
          } else setClickedOnMenu(true);
        }}
        onKeyDownCapture={(e) => {
          setEnterSelection(enterPressed(e));
        }}
      >
        <Select
          defaultValue={localValue}
          isSearchable={isSearchable}
          options={selectableOptions}
          className={customClassName}
          classNamePrefix={customClassName}
          menuPlacement="auto"
          styles={CustomStyle}
          onChange={(option) => {
            setValue(option);
            stopEditing();
          }}
          autoFocus
          openMenuOnFocus
          {...(isSearchable && {
            onMenuOpen,
            menuPosition: 'fixed',
            filterOption: selectFilter,
            inputValue: inputValue,
            onInputChange: onInputChange,
          })}
        />
      </div>
    );
  }
);
