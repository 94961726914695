import moment from 'moment/moment';
import {
  inactiveJobStatuses,
  INVOICABLE_JOB_STATUSES,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_COMPLETION_PENDING,
  JOB_STATUS_FAILED,
  JOB_STATUS_AVAILABLE,
  JOB_STATUS_CREATED,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_MAPPING_COMPLETE,
  JOB_STATUSES,
  JOB_TYPES,
  JOBS_HISTORY_CSV_EXPORT_COLUMNS,
  mappableJobStatuses,
  viewableJobStatuses,
} from '../constants/jobConstants';
import {
  isAdmin,
  isMapper,
  isOnboardingUser,
  isSuperAdmin,
} from './userHelper';
import { hoursEditableForUser } from './jobsTableHelper';

export function generateMappingJobTitle(mappingJobDetails) {
  let title = `Job Mappings - #${mappingJobDetails.id}`;
  if (isClinicJob(mappingJobDetails)) {
    title += ` (Clinic ${mappingJobDetails.practice_id} - ${mappingJobDetails.practice_name} - ${mappingJobDetails.practice_city}, ${mappingJobDetails.practice_state})`;
  } else if (
    isCustomJob(mappingJobDetails) &&
    mappingJobDetails.project_tracking
  ) {
    title += ' (' + mappingJobDetails.project_tracking + ')';
  }
  return title;
}

export function jobBelongsToUser(jobDetails, userId) {
  return jobDetails.claimed_by && jobDetails.claimed_by === userId;
}

export function jobActiveBelongsToUser(jobDetails, userId) {
  return (
    jobBelongsToUser(jobDetails, userId) &&
    mappableJobStatuses.includes(jobDetails.status)
  );
}

export function jobMappingsAllowedForUser(jobDetails, userInfo) {
  return (
    (jobBelongsToUser(jobDetails, userInfo.id) &&
      mappableJobStatuses.includes(jobDetails.status)) ||
    (isAdmin(userInfo) && viewableJobStatuses.includes(jobDetails.status))
  );
}

export function jobMappingsEditableByUser(jobDetails, userInfo) {
  return (
    mappableJobStatuses.includes(jobDetails.status) &&
    jobDetails.claimed_by &&
    ((isAdmin(userInfo) && !isOnboardingUser(userInfo)) ||
      jobDetails.claimed_by === userInfo.id)
  );
}

export function isClinicJob(mappingJobDetails) {
  return mappingJobDetails.job_type === JOB_TYPES.clinic.value;
}

export function isCustomJob(mappingJobDetails) {
  return mappingJobDetails.job_type === JOB_TYPES.custom.value;
}

export function clinicDetailsAllowedForUser(mappingJobDetails, userInfo) {
  return (
    isClinicJob(mappingJobDetails) &&
    (isAdmin(userInfo) || jobBelongsToUser(mappingJobDetails, userInfo.id))
  );
}

export function canClaimJob(jobDetails, userInfo) {
  return (
    jobDetails.status === JOB_STATUS_AVAILABLE &&
    (isAdmin(userInfo) || isMapper(userInfo)) &&
    !isOnboardingUser(userInfo) &&
    moment.utc(Date.now()).format('YYYY-MM-DD') >= jobDetails.available_at
  );
}

export function canMarkJobMappingComplete(jobDetails, userInfo) {
  return (
    jobDetails.claimed_by &&
    jobDetails.status === JOB_STATUS_IN_PROGRESS &&
    (jobDetails.claimed_by === userInfo.id || isAdmin(userInfo)) &&
    !isOnboardingUser(userInfo)
  );
}

export function canMarkJobCompleted(jobDetails, userInfo) {
  return isAdmin(userInfo) && jobDetails.status === JOB_STATUS_MAPPING_COMPLETE;
}

export function canMarkJobAsInvoiced(jobDetails, userInfo) {
  return (
    isSuperAdmin(userInfo) &&
    !jobDetails.invoiced &&
    INVOICABLE_JOB_STATUSES.includes(jobDetails.status)
  );
}

export function canDeleteJob(jobDetails, userInfo) {
  return (
    (isAdmin(userInfo) && !isOnboardingUser(userInfo)) ||
    (isOnboardingUser(userInfo) &&
      [JOB_STATUS_CREATED, JOB_STATUS_AVAILABLE].includes(jobDetails.status))
  );
}

export function canEditJobDetails(userInfo) {
  return isAdmin(userInfo);
}

export const downloadJobsCSV = (gridApi, isJobsPage) => {
  const getColumnKeys = () => {
    const allColumns = gridApi.getAllDisplayedColumns();
    let columnKeys = isJobsPage
      ? allColumns
          .filter((col) => col.getColId() !== 'action')
          .map((col) => col.getColId())
      : JOBS_HISTORY_CSV_EXPORT_COLUMNS;

    // Check if "rate" column is in the CSV, and add "rate_type" column after it.
    const rateIndex = columnKeys.indexOf('rate');
    if (rateIndex !== -1) {
      columnKeys.splice(rateIndex + 1, 0, 'rate_type');
    }

    return columnKeys;
  };

  const processCell = (params) => {
    switch (params.column.colId) {
      case 'id':
        return `#${params.value}`;
      case 'status':
        return JOB_STATUSES[params.value];
      case 'claimed_by_formatted':
        return params.value?.label;
      case 'rate':
        return `$${params.value}`;
      default:
        return params.formatValue(params.value);
    }
  };

  gridApi.exportDataAsCsv({
    fileName: `jobs-data-${moment(Date.now()).format(
      'YYYY-MM-DD_hh.mm.ss'
    )}.csv`,
    columnKeys: getColumnKeys(),
    processCellCallback: processCell,
  });
};

export function canUnhideJobFromMapper(jobDetails, userInfo) {
  return (
    isAdmin(userInfo) &&
    jobDetails?.status === JOB_STATUS_IN_PROGRESS &&
    jobDetails?.hidden_from_mapper
  );
}

const jobDetailsFieldEditConditions = () => {
  const commonJobDetailsFields = [
    'priority',
    'available_at',
    'salesforce_case_number',
    'onboarding_category',
    'admin_description',
  ];

  let conditions = {
    project_tracking: (userInfo) =>
      isAdmin(userInfo) && !isOnboardingUser(userInfo),
    practice_type: (userInfo, mappingJobDetails) => {
      if (isAdmin(userInfo)) {
        return ![
          JOB_STATUS_FAILED,
          JOB_STATUS_COMPLETION_PENDING,
          JOB_STATUS_COMPLETED,
        ].includes(mappingJobDetails.status);
      }
      return (
        isMapper(userInfo) &&
        jobBelongsToUser(mappingJobDetails, userInfo.id) &&
        (mappingJobDetails.status === JOB_STATUS_IN_PROGRESS ||
          mappingJobDetails.status === JOB_STATUS_MAPPING_COMPLETE)
      );
    },
    vaccine_protocol: (userInfo, mappingJobDetails) =>
      isAdmin(userInfo) && !isStatusInactive(mappingJobDetails),
    rate: (userInfo, mappingJobDetails) =>
      isAdmin(userInfo) && !mappingJobDetails.invoiced,
    rate_type: (userInfo, mappingJobDetails) =>
      isAdmin(userInfo) &&
      !mappingJobDetails.invoiced &&
      !isStatusInactive(mappingJobDetails) &&
      !isOnboardingUser(userInfo),
    mapped_time: (userInfo, mappingJobDetails) =>
      isAdmin(userInfo) &&
      hoursEditableForUser(
        mappingJobDetails.status,
        mappableJobStatuses.concat([
          JOB_STATUS_COMPLETED,
          JOB_STATUS_COMPLETION_PENDING,
        ]),
        mappingJobDetails.invoiced
      ),
  };
  // Add common fields with the shared condition
  commonJobDetailsFields.forEach((field) => {
    conditions[field] = (userInfo, mappingJobDetails) =>
      isAdmin(userInfo) && !isStatusInactive(mappingJobDetails);
  });

  return conditions;
};

const isTheFieldEditable = jobDetailsFieldEditConditions();

export const isEditable = (field, userInfo, mappingJobDetails) => {
  return isTheFieldEditable[field](userInfo, mappingJobDetails);
};

const isStatusInactive = (mappingJobDetails) => {
  return inactiveJobStatuses.includes(mappingJobDetails.status);
};

export const isPracticeTypeAvailable = (userInfo, mappingJobDetails) => {
  return (
    isClinicJob(mappingJobDetails) &&
    (jobBelongsToUser(mappingJobDetails, userInfo.id) || isAdmin(userInfo))
  );
};
